import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetStaticPropsContext } from 'next';

import Service from 'lib/cms/service';
import { getNotifications } from 'lib/notification';
import { getPromotionPopup } from 'lib/promotionpopup';

import Page, { PageProps } from 'components/Page';
import { Page as IPage } from 'interfaces/cms/page';
import { Preview } from 'components/common/Preview';
import nextI18nextConfig from '../../next-i18next.config';

function HomePage({ page, hreflangs, previewMode }: PageProps) {
  return (
    <>
      <Preview previewMode={previewMode} />
      <Page page={page} hreflangs={hreflangs} />
    </>
  );
}

export async function getStaticProps({
  locale,
  preview,
}: GetStaticPropsContext) {
  const strapi = new Service();

  const [layout, settings, productTypes] = await Promise.all([
    strapi.getLayout(locale),
    strapi.getSettings(locale),
    strapi.getProductTypes(locale),
  ]);

  const previewMode = preview ? true : null;

  const getHrefLangs = async () => {
    const data = await strapi.getHrefLangs('', locale);
    const hreflangs = data?.attributes?.seo?.hreflangs || [];

    return hreflangs;
  };

  let page: IPage = null;
  if (previewMode) {
    page = await strapi.getHomePagePreviewByLocale(locale);
  } else {
    page = await strapi.getHomePageByLocale(locale);
  }

  if (!layout || !page) {
    return {
      notFound: true,
      revalidate: 1,
    };
  }

  const notifications = getNotifications(layout, page);
  const promotionPopup = getPromotionPopup(layout, page);

  let hreflangs = null;
  if (page) {
    hreflangs = await getHrefLangs();
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'], nextI18nextConfig)),
      locale,
      layout,
      notifications,
      popup: promotionPopup,
      settings,
      page,
      isMultipleLang: true,
      productTypes,
      previewMode,
      hreflangs,
    },
    revalidate: 1,
  };
}

export default HomePage;
